import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Main } from '../components/Styled';

import Img from 'gatsby-image';
import Accordion from '../components/Accordion';
import Hr from '../components/BlogPosts/utils/Hr';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';
import Form from '../components/Form';
import Marquee from '../components/Marquee';
import Points from '../components/Points';

import { PageProps, graphql, useStaticQuery } from 'gatsby';
import { SEO } from '../SEO';
import { Site } from '../components/Site';
import { Color } from '../components/utils/getColor';
import { customers, partners } from '../constants';
import { points as keypoints } from '../constants/points';
import { Copy } from './product';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default (props: PageProps) => {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
    }
  }, []);

  const data = useStaticQuery(graphql`
    query HeroImageLandingPage {
      file(relativePath: { eq: "ui/landingPage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Site seo={SEO.voiceAnalytics} {...props}>
      <Main>
        <Container>
          <Grid>
            <CopyWrapper>
              <H1>AI voice analytics software that enables you to understand what your customers are saying.</H1>
              <Copy sectionColor={Color.Blue}>
                Enhance CX, evaluate agent performance, rapdily transcribe 100% of calls and improve quality assurance
                standards. daisee provides insights into your customers’ voice, surfacing opportunities, obstacles and
                risks like <Emphasis>never before.</Emphasis>
              </Copy>
              <Accordion />
            </CopyWrapper>
            <ImgWrapper>
              <Img fluid={data.file.childImageSharp.fluid} />
            </ImgWrapper>
          </Grid>

          <span id="start-a-demo" />
          <Marquee title="Trusted By Our Customers and Partners" list={[...customers, ...partners]} />
          <FormWrapper>
            <H2>Contact us to start a free demo today</H2>
            <Keypoints>
              <Points points={keypoints} quantity={3} isGreen />
            </Keypoints>
            <Hr padding="1.5rem 0 0.5rem" />
            <Form
              formName="start-a-demo"
              successMessage="Thank you, we will be in touch with you soon"
              errorMessage="Oops, something went wrong - please try again"
              action="Contact"
              isGtmForm
            />
          </FormWrapper>
        </Container>
      </Main>
      <Footer />
    </Site>
  );
};

const Emphasis = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue};
`;

const Keypoints = styled.div`
  display: grid;
  gap: 1.5rem;
  padding: 3rem 0;
  justify-content: center;
`;

const Grid = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 9rem 1.5rem 3rem;

  @media all and (max-width: 48em) {
    grid-template-columns: 1fr;
    padding: 9rem 1.5rem 6rem;
  }
`;

const ImgWrapper = styled.div`
  margin-right: -10vw;
`;

const H1 = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.purple};

  @media all and (max-width: 26.563em) {
    font-size: 2rem;
  }
`;

const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -1px;
  margin: 0;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.purple};
  margin: 0 auto;
  text-align: center;
  padding-bottom: 1.5rem;

  @media all and (max-width: 26.563em) {
    font-size: 0.875rem;
  }
`;

const CopyWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-rows: min-content;
  padding: 0 1.5rem;
  transition: all 0.75s ease;
  position: relative;
  height: fit-content;

  @media all and (max-width: 48em) {
    padding: 0;
    grid-row: 1;
  }
`;

const FormWrapper = styled.div`
  padding: 0 9rem 9rem;

  @media all and (max-width: 48em) {
    padding: 0 3rem 9rem;
  }
`;
