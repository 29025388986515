import { AnimatePresence, motion } from 'framer-motion';
import { lighten } from 'polished';
import React, { useContext, useState } from 'react';
import { BarChart2, ChevronDown, Crosshair, TrendingUp } from 'react-feather';
import styled, { ThemeContext } from 'styled-components';
import { Color } from '../utils/getColor';

export interface AccordionPoints {
  id: number;
  icon: JSX.Element;
  copy: string;
  color: Color;
  body: string;
  isOpen: boolean;
}

interface Props {
  points?: AccordionPoints[];
}

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

const defaultPoints = [
  {
    id: 0,
    icon: <Crosshair />,
    copy: '90%+ Accuracy in transcription and quality scoring*',
    color: Color.Pink,
    body:
      '* As verified through comparing a control group of human scores against the word error rate versus the ground truth assessment undertaken by daisee.',
    isOpen: false,
  },
  {
    id: 1,
    icon: <TrendingUp />,
    copy: 'Average increase of 22% in customer satisfaction*',
    color: Color.Blue,
    body: '* As reported by MYOB after integrating daisee in their contact centre workflow.',
    isOpen: false,
  },
  {
    id: 2,
    icon: <BarChart2 />,
    copy: '300% average return on investment in 12 months*',
    color: Color.Yellow,
    body:
      "* Based on the average productivity uplift of daisee customers' contact centre agents and QA teams, and a conservative estimate of a 1% increase in revenue.",
    isOpen: false,
  },
] as AccordionPoints[];

const Accordion = ({ points = defaultPoints }: Props) => {
  const [accordion, setAccordion] = useState(points);

  const handleToggleAccordion = (i: number) => {
    const items = [...accordion];
    const item = { ...items[i] };
    item.isOpen = !item.isOpen;
    items[i] = item;
    setAccordion(items);
  };

  return (
    <StatisticWrapper>
      {accordion.length &&
        accordion.map((point, i) => (
          <StyledAccordion key={point.id}>
            <AccordionHead onClick={() => handleToggleAccordion(i)}>
              <Statistic iconColor={point.color}>
                <IconWrapper iconColor={point.color}>{point.icon}</IconWrapper>
                <p style={{ marginLeft: '1.25rem' }}>{point.copy}</p>
                <StatisticButton type="button" isOpen={point.isOpen}>
                  <ChevronDown />
                </StatisticButton>
              </Statistic>
            </AccordionHead>
            <AnimatePresence initial={false}>
              {point.isOpen && (
                <AccordionBody
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={variants}
                  transition={{ duration: 0.5, ease: [0.7, -0.005, 0, 1.005] }}
                >
                  <p style={{ padding: '1.5rem' }}>{point.body}</p>
                </AccordionBody>
              )}
            </AnimatePresence>
          </StyledAccordion>
        ))}
    </StatisticWrapper>
  );
};

const StatisticWrapper = styled.div`
  padding: 3rem 0;
  display: grid;
  gap: 1.5rem;
`;

interface StatisticI {
  iconColor: Color;
}

const getColor = (color: Color) => {
  const themeContext = useContext(ThemeContext);
  switch (color) {
    case Color.Blue:
    default:
      return themeContext.colors.blue;
    case Color.Yellow:
      return themeContext.colors.yellow;
    case Color.Pink:
      return themeContext.colors.pink;
    case Color.Purple:
      return themeContext.colors.violet;
  }
};

const StyledAccordion = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  border-radius: 0.5rem;
`;

const AccordionHead = styled.div`
  cursor: pointer;
`;

const AccordionBody = styled(motion.div)`
  background: ${({ theme }) => lighten(0.05, theme.colors.grey200)};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  overflow: hidden;

  p {
    font-size: 0.875rem;
  }
`;

const IconWrapper = styled.div<StatisticI>`
  position: relative;
  display: flex;
  justify-content: center;

  &:before {
    content: '';
    width: 48px;
    height: 48px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: ${({ iconColor }) => lighten(0.3, getColor(iconColor))};
  }
`;

const Statistic = styled.div<StatisticI>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.05fr 1.5fr 0.05fr;
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.grey200};
  font-weight: 500;
  border-radius: 0.5rem;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grey300};

  svg {
    position: relative;
    stroke: ${({ iconColor }) => getColor(iconColor)};
    &:before {
      content: '';
      width: 48px;
      height: 48px;
      position: absolute;
      background: ${({ iconColor }) => lighten(0.3, getColor(iconColor))};
    }
  }
`;

interface ButtonI {
  isOpen: boolean;
}

const StatisticButton = styled.button<ButtonI>`
  margin: 0;
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.colors.grey400};
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export default Accordion;
