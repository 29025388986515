import React from 'react';
import styled from 'styled-components';

interface Props {
  padding?: string;
  margin?: string;
}

export default ({ padding, margin }: Props) => <Hr padding={padding} margin={margin} />;

const Hr = styled.hr<Props>`
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  margin: 0;
  height: 1px;
  width: 100%;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`;
